import * as React from "react";
import Redirect from "../../../../components/redirect";
//--------------------------------------------------

  const url = "https://www.thestandnews.com/city/%E8%B7%AF%E9%81%8E%E5%8C%97%E8%A7%92%E9%81%87%E8%A6%8B20%E4%BB%B6%E8%97%9D%E8%A1%93%E8%A3%9D%E7%BD%AE%E4%B8%BB%E8%BE%A6%E6%96%B9%E5%86%80%E5%A4%A7%E7%9C%BE%E6%84%9F%E5%8F%97%E8%97%9D%E8%A1%93%E5%9C%A8%E8%BA%AB%E9%82%8A";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
